import React from 'react';
import styled, { css } from 'styled-components';

// XDでの記載
// <ButtonPrimary styleType={reguler}>
// <ButtonPrimary styleType={small}>
// <ButtonPrimary styleType={table}>

// コードの書き方
// ButtonPrimary(styleType=reguler)
// ButtonPrimary(styleType=small)
// ButtonPrimary(styleType=table)

// // いままでの 書き方は今後なくなります
// <ButtonPrimary small>
// <ButtonPrimary table>

const ButtonPrimary = styled.button`
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 5px;
  ${(props) =>
    props.disabled
      ? css`
          background: #dadada;
          color: #9a9a9a;
          border: 1px solid #dadada;
        `
      : `
      background : #e8a100;
      color : #FFFFFF;
      border : 1px solid #e8a100;
      &:hover {
        background: #ffca61;
        border : 1px solid #ffca61;
      }
   `}
  ${(props) => getStyle(props)}
`;

const getStyle = (props) => {
  if (props.styleType == 'regular') {
    return `
      width : 350px;
      height : 55px;
      font-size : 18px;
      font-weight: bold;
    `;
  } else if (props.styleType === 'small') {
    return `
      width : 200px;
      height : 55px;
      font-size : 16px;
      font-weight: bold;
		`;
  } else if (props.styleType === 'table') {
    return `
      width : 100px;
      height : 30px;
      font-size : 12px;
      font-weight: bold;
		`;
  } else if (props.styleType === 'tableSmall') {
    return `
      width : 70px;
      height : 30px;
      font-size : 12px;
      font-weight: bold;
		`;
  } else if (props.styleType === 'dialog') {
    return `
      width : 100px;
      height : 40px;
      font-size : 14px;
      font-weight: bold;
      `;
  } else if (props.styleType === 'search') {
    return `
      width : 60px;
      height : 24px;
      font-size : 14px;
      font-weight: bold;
		`;
  } else {
    return `
      width : 10px;
      font-size : 8px;
		`;
  }
};

export default ButtonPrimary;
