import React from 'react';
import styled, { css } from 'styled-components';
import { FaSearch } from 'react-icons/fa';

const ButtonSearch = ({ handleOnClick, className }) => {
  return (
    <ButtonSearchtWrapper className={className} onClick={handleOnClick}>
      <FaSearch
        style={{
          fontSize: '2.0rem',
          color: '#41559e',
        }}
      />
    </ButtonSearchtWrapper>
  );
};

const ButtonSearchtWrapper = styled.button`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  color: #fff;
  text-decoration: none;
  text-align: center;
  margin: 10px 0;
  height: 32px;
  width: 32px;
  border: 1px solid #b1b1b1;
  box-shadow: 1px 1px 2px 0px #b5b5b5;
  &:hover {
    background: #ffefc0;
  }
`;

export default ButtonSearch;
